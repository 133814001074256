<template>
  <v-form ref="form" v-model="valid" lazy-validation style="overflow: auto">
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="vm.item.statusKey"
            :items="ctx.src.dict['INS_VISIT_STATUS'].items"
            :label="'Статус'"
            item-text="value"
            item-value="key"
            required
            :rules="requiredRules"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="vm.item.typeKey"
            :items="ctx.src.dict['INS_VISIT_TYPE'].items"
            :label="'Тип'"
            item-text="value"
            item-value="key"
            required
            :rules="requiredRules"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="vm.item.visitDate"
            label="Дата создания"
            type="date"
            required
            :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="vm.item.visitTime"
            label="Время создания"
            type="time"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="vm.item.organizationId"
            :items="ctx.src.org.byType('3')"
            :label="'Поставщик'"
            item-text="fullName"
            item-value="id"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.item.doctorId"
            :items="ctx.src.doctors.items"
            :label="'Доктор'"
            item-text="fullName"
            item-value="id"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="vm.item.actionDate"
            label="Дата актива"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="vm.item.actionTime"
            label="Время актива"
            type="time"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col v-if="!byInsured" cols="12" md="12">
          <v-autocomplete
            v-model="vm.item.insuredId"
            :items="ctx.src.insured.items"
            :label="'Застрахованный'"
            item-text="label"
            item-value="id"
            required
            :rules="requiredRules"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="8">
          <v-text-field
            v-model="vm.item.contacts"
            label="Контакты"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field v-model="vm.item.age" label="Возраст"></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field v-model="vm.item.address" label="Адрес"></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12" >
          <v-textarea v-model="vm.item.reason" label="Причина" rows="1" auto-grow></v-textarea>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field
            v-model="vm.item.diagnosis"
            label="Диагноз"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-textarea
            v-model="vm.item.comment"
            label="Примечание"
            rows="1"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import ctx from "@/ctx";

  export default {
    name: "Contract",
    props: {
      vm: Object,
      byInsured: { type: Boolean, default: false }
    },
    data: () => ({
      ctx,
      valid: false,
      requiredRules: [v => !!v || "Обязательное поле"],
    }),
    methods: {
      validate() {
        this.$refs.form.validate();
      }
    },
    mounted() {
      this.vm.detail = this;
      this.validate();
    }
  };
</script>
